import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component:  ()=>import('@/views/Login'),
  },
  {
    path: '/Main',
    name: 'Main',
    component:  ()=>import('@/views/Main'),
    children:[
      {
        path: '/Home',
        name: 'Home',
        component:  ()=>import('@/views/Home'),
        meta:{
          title: '首页',
          isTab: true
        }
      },
      {
        path: '/addBanner',
        name: 'addBanner',
        component:  ()=>import('@/views/banner/addBanner'),
        meta:{
          title: '添加Banner',
          isTab: true,
          close: true
        }
      },
      {
        path: '/bannerList',
        name: 'bannerList',
        component: ()=>import('@/views/banner/bannerList'),
        meta:{
          title: 'banner列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/newImgTxt',
        name: 'newImgTxt',
        component:  ()=>import('@/views/imgTxt/newImgTxt'),
        meta:{
          title: '发布图文',
          isTab: true,
          close: true
        }
      },
      {
        path: '/imgTxtList',
        name: 'imgTxtList',
        component:  ()=>import('@/views/imgTxt/imgTxtList'),
        meta:{
          title: '图文列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/editImgTxt',
        name: 'editImgTxt',
        component:  ()=>import('@/views/imgTxt/editImgTxt'),
        meta:{
          title: '编辑图文',
          isTab: true,
          close: true
        }
      },
      {
        path: '/newShop',
        name: 'newShop',
        component:  ()=>import('@/views/shop/newShop'),
        meta:{
          title: '新建商铺',
          isTab: true,
          close: true
        }
      },
      {
        path: '/editShop',
        name: 'editShop',
        component:  ()=>import('@/views/shop/editShop'),
        meta:{
          title: '编辑商铺',
          isTab: true,
          close: true
        }
      },
      {
        path: '/shopList',
        name: 'shopList',
        component:  ()=>import('@/views/shop/shopList'),
        meta:{
          title: '商铺列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/shopType',
        name: 'shopType',
        component:  ()=>import('@/views/shop/shopType'),
        meta:{
          title: '商铺类型',
          isTab: true,
          close: true
        }
      },
      {
        path: '/editGoods',
        name: 'editGoods',
        component: ()=>import('@/views/goods/editGoods'),
        meta:{
          title: '编辑商品',
          isTab: true,
          close: true
        }
      },
      {
        path: '/goodsList',
        name: 'goodsList',
        component:  ()=>import('@/views/goods/goodsList'),
        meta:{
          title: '商品列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/goodsType',
        name: 'goodsType',
        component:  ()=>import('@/views/goods/goodsType'),
        meta:{
          title: '商品类型',
          isTab: true,
          close: true
        }
      },
      {
        path: '/newGoods',
        name: 'newGoods',
        component:  ()=>import('@/views/goods/newGoods'),
        meta:{
          title: '添加商品',
          isTab: true,
          close: true
        }
      },
      {
        path: '/afterSales',
        name: 'afterSales',
        component:  ()=>import('@/views/order/afterSales'),
        meta:{
          title: '售后订单',
          isTab: true,
          close: true
        }
      },
      {
        path: '/orderSearch',
        name: 'orderSearch',
        component:  ()=>import('@/views/order/orderSearch'),
        meta:{
          title: '订单列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/serviceCreate',
        name: 'serviceCreate',
        component:  ()=>import('@/views/service/serviceCreate'),
        meta:{
          title: '创建项目',
          isTab: true,
          close: true
        }
      },
      {
        path: '/serviceEdit',
        name: 'serviceEdit',
        component:  ()=>import('@/views/service/serviceEdit'),
        meta:{
          title: '编辑项目',
          isTab: true,
          close: true
        }
      },
      {
        path: '/serviceList',
        name: 'serviceList',
        component:  ()=>import('@/views/service/serviceList'),
        meta:{
          title: '项目列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/miniUserList',
        name: 'miniUserList',
        component:  ()=>import('@/views/user/miniUserList'),
        meta:{
          title: '一般用户',
          isTab: true,
          close: true
        }
      },
      {
        path: '/adminUserList',
        name: 'adminUserList',
        component:  ()=>import('@/views/user/adminUserList'),
        meta:{
          title: '权限用户',
          isTab: true,
          close: true
        }
      },
      {
        path: '/batchQrMag',
        name: 'batchQrMag',
        component:  ()=>import('@/views/inner/batchQrMag'),
        meta:{
          title: '固定码',
          isTab: true,
          close: true
        }
      },
      {
        path: '/innerSearchMiniUser',
        name: 'innerSearchMiniUser',
        component:  ()=>import('@/views/inner/innerSearchMiniUser'),
        meta:{
          title: '用户查询',
          isTab: true,
          close: true
        }
      },
      {
        path: '/innerUserList',
        name: 'innerUserList',
        component:  ()=>import('@/views/inner/innerUserList'),
        meta:{
          title: '社区居民',
          isTab: true,
          close: true
        }
      },
      {
        path: '/complaint',
        name: 'complaint',
        component:  ()=>import('@/views/other/complaint'),
        meta:{
          title: '投诉管理',
          isTab: true,
          close: true
        }
      },
      {
        path: '/complaintDetails',
        name: 'complaintDetails',
        component:  ()=>import('@/views/other/complaintDetails'),
        meta:{
          title: '投诉详情',
          isTab: true,
          close: true
        }
      },
      {
        path: '/enterSetting',
        name: 'enterSetting',
        component:  ()=>import('@/views/other/enterSetting'),
        meta:{
          title: '入园设置',
          isTab: true,
          close: true
        }
      },
      {
        path: '/expressTemplate',
        name: 'expressTemplate',
        component:  ()=>import('@/views/other/expressTemplate'),
        meta:{
          title: '物流模板',
          isTab: true,
          close: true
        }
      },
      {
        path: '/expressTemplateNew',
        name: 'expressTemplateNew',
        component:  ()=>import('@/views/other/expressTemplateNew'),
        meta:{
          title: '新增模板',
          isTab: true,
          close: true
        }
      },
      {
        path: '/evaluate',
        name: 'evaluate',
        component:  ()=>import('@/views/evaluate/evaluate'),
        meta:{
          title: '评价管理',
          isTab: true,
          close: true
        }
      },
      {
        path: '/askList',
        name: 'askList',
        component:  ()=>import('@/views/ask/askList'),
        meta:{
          title: '问卷列表',
          isTab: true,
          close: true
        }
      },
      {
        path: '/askCreate',
        name: 'askCreate',
        component:  ()=>import('@/views/ask/askCreate'),
        meta:{
          title: '创建问卷',
          isTab: true,
          close: true
        }
      },
      {
        path: '/questionCreate',
        name: 'questionCreate',
        component:  ()=>import('@/views/ask/questionCreate'),
        meta:{
          title: '初始化问题',
          isTab: true,
          close: true
        }
      },
      {
        path: '/questionSet',
        name: 'questionSet',
        component:  ()=>import('@/views/ask/questionSet'),
        meta:{
          title: '问题设置',
          isTab: true,
          close: true
        }
      },
      {
        path: '/inputAnswer',
        name: 'inputAnswer',
        component:  ()=>import('@/views/ask/inputAnswer'),
        meta:{
          title: '输入型答案',
          isTab: true,
          close: true
        }
      },
      {
        path: '/askReport',
        name: 'askReport',
        component:  ()=>import('@/views/ask/askReport'),
        meta:{
          title: '问卷报表',
          isTab: true,
          close: true
        }
      },
    ]
  },
  {
    path: '/DataV',
    name: 'DataV',
    component:  ()=>import('@/views/DataV'),
  },

  {
    path: '/NoPermiss',
    name: 'NoPermiss',
    component:  ()=>import('@/views/NoPermiss'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes
})

export default router
