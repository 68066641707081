import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ELementPlusIconsVue from '@element-plus/icons-vue'                  //引入Element-icons需npm 安装
import dataScreen from '@jiaminghi/data-view'
import zhCn from 'element-plus/es/locale/lang/zh-cn'        //引入中文包

const app=createApp(App)

app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(dataScreen)
app.use(zhCn)
//===全局注册ElementPlusIcons
for (const [name, component] of Object.entries(ELementPlusIconsVue)){
    app.component(name, component);
}
//END 全局注册ElementPlusIcons

app.mount('#app')

//后端项目的URL根路径
let baseUrl = "https://service.luckydogdesign.cn/tree-api/"
// let baseUrl = "https://mwhujun.mynatapp.cc/tree-api/"
// let baseUrl = "https://service.cdlongxian.com/tree-api/"
let basePhpUrl = "https://www.luckydog.design/PHP/hlx/"
app.config.globalProperties.$baseUrl = baseUrl //设置全局变量$baseUrl ...
app.config.globalProperties.$basePhpUrl = basePhpUrl

//封装全局Ajax公共函数
app.config.globalProperties.$http = function (url, method, data, async, fun, that){
    $.ajax({
        url: url,
        type: method,
        dataType: 'json',
        contentType: 'application/json',
        xhrFields:{
            withCredentials: true
        },
        headers:{
            "token": localStorage.getItem("hlxToken")
        },
        async: async,
        data: JSON.stringify(data),
        success: function (res){
            // that.loading = false
            fun(res)
        },
        fail: function (res){
            console.log('Request Fail')
            console.log(res)
            that.loading = false
        },
        error: function (res){
            console.log('Request Error')
            console.log(res)
            that.$message.error(res.responseJSON.error)
            that.loading = false
        },
    })
}

app.config.globalProperties.$requestPHP=function (phpFile,phpMethod,paraData,func,that){
    $.ajax({
        url: phpFile,
        type: phpMethod,
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        headers: {
            "token": localStorage.getItem("hlxToken")
        },
        async: true,
        data: paraData,
        success: function(res) {
            that.loading = false
            func(res)
        },
        error: function(rese) {
            that.loading = false
            that.$message.error("错误")
            console.log(rese)
        }
    })
}

//封装用于判断用户是否具有某些权限的公共函数
app.config.globalProperties.isAuth = function(tagPermissions) {
    let userPermissions = localStorage.getItem("permissions");

    let flag = false
    for (let one of tagPermissions) {
        if (userPermissions.includes(Encrypt(one))) {
            flag = true;
            break;
        }
    }
    return flag;
}

//全局变量
app.config.globalProperties.$defaultImgGG='https://flamen.oss-cn-chengdu.aliyuncs.com/hlxDevelop/sp1.jpg'
app.config.globalProperties.$imgUploadUrl = 'https://www.luckydog.design/vue/PHP/uploadFiles/'
app.config.globalProperties.$imgServerUrl = "https://www.luckydog.design/vue/PHP/uploadFileVue.php"
app.config.globalProperties.$previewImgServerUrl = "http://uploadfiles.luckydogdesign.cn/home/"
// app.config.globalProperties.$previewImgServerUrl = "http://47.108.145.255:9000/home/"

app.config.globalProperties.$regionAll = '北京市,天津市,上海市,重庆市,河北省,山西省,辽宁省,吉林省,黑龙江省,江苏省,浙江省,安徽省,福建省,江西省,山东省,河南省,湖北省,湖南省,广东省,海南省,四川省,贵州省,云南省,陕西省,内蒙古自治区,广西壮族自治区,宁夏回族自治区,甘肃省,青海省,新疆维吾尔自治区,西藏自治区'
app.config.globalProperties.$tabsString = 'Home,'